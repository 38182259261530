<template>
  <BaseBreadcrumb :items="breadcrumbs" />
  <h1>Update User</h1>
  <div class="p-grid">
    <div class="p-col-12 p-sm-6">
      <UserForm @submitted="update" :user="user" :editMode="true" />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast.js'
import UserForm from '@/components/UserForm'
import { firestore } from '@/services/firebase'
import BaseBreadcrumb from '@/components/BaseBreadcrumb.vue'

export default {
  components: { UserForm, BaseBreadcrumb },
  setup() {
    const toast = useToast()
    const router = useRouter()
    const route = useRoute()
    const user = ref({})
    const breadcrumbs = [{ label: 'Users', to: '/users' }, { label: 'Update' }]

    function getUser() {
      firestore
        .collection('users')
        .doc(route.params.id)
        .get()
        .then(doc => {
          if (doc.exists) {
            user.value = doc.data()
            user.value.id = doc.id
          }
        })
    }

    function update(formData) {
      firestore
        .collection('users')
        .doc(route.params.id)
        .set({ name: formData.name, roles: formData.roles }, { merge: true })
        .then(() => {
          router.push({ name: 'UserIndex' })
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Update Failed',
            detail: err.message,
            life: 3000
          })
        })
    }

    getUser()

    return { update, user, breadcrumbs }
  }
}
</script>
